import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import ScrollspyNav from 'react-scrollspy-nav';

import '../../generic-page.scss';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const NikkeGuidesWeaponTypesPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Weapon types</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_7.png"
            alt="Weapon types"
          />
        </div>
        <div className="page-details">
          <h1>Weapon types</h1>
          <h2>A guide for the weapons available in NIKKE.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Weapon types" />
              <p>
                In NIKKE each character is equipped with one of the 6 available
                weapons:
              </p>
              <ul>
                <li>Assault Rifle</li>
                <li>Minigun</li>
                <li>Rocket Launcher</li>
                <li>Shotgun</li>
                <li>SMG</li>
                <li>Sniper Rifle</li>
              </ul>
              <p>
                <strong>Assault Rifles</strong> are considered to be the most
                versatile weapons - they have good damage, range values and
                magazine sizes. <strong>Miniguns</strong> have nearly the same
                range values as Assault Rifles, but they have huge magazines so
                you can shoot for ages using them.
              </p>
              <p>
                <strong>Shotguns</strong> have huge damage, but their range is
                pretty small and also their magazines require to be reloaded
                often. <strong>Sniper Rifles</strong> are the opposite - they
                are only good when you attack enemies that are far away and you
                also have to charge each shot, but in exchange, they deal pretty
                good damage.
              </p>
              <p>
                <strong>SMGs</strong> have a similar range to Shotguns, but
                their damage is pretty low. As for{' '}
                <strong>Rocket Launchers</strong> - they are pretty unique as
                they have no range restrictions and they deal massive AOE
                damage, but their magazines usually allow you to only shoot once
                before you need to reload.
              </p>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Ranges" />
              <StaticImage
                src="../../../images/nikke/generic/guide_range.jpg"
                alt="Range info"
              />
              <p>
                Each weapon has a different range where it's performing at
                maximum potential and if you attack enemies that are closer (or
                further) you will receive damage and accuracy penalty.
              </p>
              <p>Here are the range values for each weapons:</p>
              <ul>
                <li>Assault Rifle - 25% to 45%</li>
                <li>Minigun - 35% to 55%</li>
                <li>Rocket Launcher - no range restrictions</li>
                <li>Shotgun - 0% to 25%</li>
                <li>SMG - 15% to 35%</li>
                <li>Sniper Rifle - 45% to 100%</li>
              </ul>
              <p>
                Before you enter a stage, you will receive information about the
                enemies that you will encounter inside. It looks like this:
              </p>
              <StaticImage
                src="../../../images/nikke/generic/stage_info.jpg"
                alt="Stage info"
              />
              <p>
                In the stage above, you can expect to meet enemies that are in
                mid (62%) and far range (38%), but none will enter the close
                range.
              </p>
              <p>
                This makes Shotguns and SMG pretty much useless in the stage,
                while ARs and Sniper Rifles will reap the enemies' lives easily.
              </p>
            </div>
            <div className="section-scroll" id="section-2">
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297247"
              ></div>
              <SectionHeader title="What's the best weapon type?" />
              <p>There is no one best weapon type.</p>
              <p>
                As you can see in the example above, you have to build a
                balanced team if you want to not feel useless in a stage that
                suddenly counters every weapon you are using.
              </p>
              <p>
                If you want to learn how to build a good, well-rounded team,
                check our Team Building guide:
              </p>
              <Row
                xs={1}
                md={2}
                lg={2}
                xxl={3}
                className="g-3 category-cards-container"
              >
                <CategoryCard
                  title="Team building"
                  link="/nikke/guides/team-building"
                  image={
                    <StaticImage
                      src="../../../images/nikke/categories/category_8.png"
                      alt="Team building"
                    />
                  }
                />
              </Row>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={['section-0', 'section-1', 'section-2']}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Weapon types</a>
                </li>
                <li>
                  <a href="#section-1">Ranges</a>
                </li>
                <li>
                  <a href="#section-2">What's the best weapon type?</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesWeaponTypesPage;

export const Head: React.FC = () => (
  <Seo
    title="Weapon Types | NIKKE | Prydwen Institute"
    description="Available weapons in Goddess of Victory: NIKKE."
  />
);
